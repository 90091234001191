body {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: #333;
  /* background-color: #F5F5ED !important; */
 
  overflow: hidden;
  margin: 0;
}
body::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
div,header, section, footer{
  position: relative;
}

